@import 'common/styles/global';

.question-card {
  margin-bottom: 20px;
  // overflow-x: hidden; 
  position: relative;
  user-select: auto;
  width: 100%;
  border-radius: 8px;
  &:last-child {
    margin-bottom: 0;
  }

  &__close-button {
    cursor: pointer;
    height: 15px;
    position: absolute;
    right: 32px;
    top: 20px;
    width: 15px;
    z-index: 5;
  }

  &__close-success-panel {
    position: absolute;
    cursor: pointer;
    top: 18px;
    right: 30px;
    font-size: 14px;
    color: var(--color-primary-pure);
  }

  &__modal-wrap {
    background: var(--color-Neutral-1);
    border-radius: 6px;
    padding: 0;
    position: relative;

    &.container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__country-blocked-panel,
  &__not-enough-funds-panel {
    padding: 2em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.025);
    background-color: $color-grey0;
  }

  &__chart {
    background-color: var(--color-Neutral-1);
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 24px;

    @media screen and (max-width: $desktop) {
      padding: 16px;
    }
  }

  &__related-markets,
  &__tabed-content,
  &__summary {
    background-color: var(--color-Neutral-1);
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 16px;
    &--count {
      padding: 4px 8px;
      background-color: var(--color-brand-01);
      color: var(--color-brand-04);
      display: inline;
      font-size: 12px;
      font-weight: 500;
      border-radius: 500px;
      margin-left: 14px;
      position: relative;
      bottom: 1px;
    }
  }
  &__related-markets {
    margin-bottom: unset;
  }
  &__email-required-panel {
    padding: 0 0 86px;
    background-color: var(--color-Neutral-1);
    border-bottom: 1px solid $color-grey0;
  }

  &__section {
    position: relative;
    z-index: 1;
    .place-bet {
      box-shadow: unset;
    }
    &--space-bottom:not(:last-child) {
      margin-bottom: 13px;
    }

    &--loading {
      height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;

      .loading--inline {
        fill: $color-grey1;
      }
    }

    &--under {
      @extend %shadows--under;
      background-color: $color-shade-4;
    }

    &:not(&--wide) {
      &.question-card__main {
        box-sizing: border-box;
        padding: 16px;
        background-color: var(--color-Neutral-1);
      }
    }
  }

  &__title {
    @extend %heading-xs;
    color: var(--color-Neutral-5);
  }
  &__section-title {
    color: $color-shade-1;
    font-size: 16px;
    margin: 0 0 1em;
    font-family: $font-title;

    &--big {
      padding: 1em 0;
      margin-bottom: 0;
      border-bottom: 1px solid $color-grey1;
    }
  }

  &__bet-history-title {
    padding-top: 2em;
    border-bottom: 0;
  }

  &__kyc-required {
    border-bottom: 1px solid $color-shade-3;
    padding-bottom: 2em;
    height: 85%;
    display: flex;
  }
  @media (min-width: $desktop) {
    &__kyc-required {
      height: unset !important;
    }
  }

  &__volume {
    &:hover .question-card__general-info-left--first__tooltip {
      opacity: 1;
      pointer-events: auto;

      &-arrow {
        left: 5%;
      }
    }
  }
  &__number-of-bets {
    &:hover .question-card__general-info-left--first__tooltip {
      opacity: 1;
      pointer-events: auto;
      z-index: 20;
    }
    &-arrow {
      left: 24px;
    }
  }
  &__volume {
    &:hover .question-card__general-info-left--first__tooltip {
      opacity: 1;
      pointer-events: auto;
      z-index: 20;
    }
  }
  &__general-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    @media screen and (max-width: #{$desktop - 1px}) {
      margin-top: 20px;
    }
    &-left {
      &--first {
        position: relative;
      }

      &--second {
        @media screen and (min-width: #{$desktop - 1px}) {
          position: relative;
        }
      }

      &,
      &--first,
      &--second {
        display: flex;
        gap: 16px;
        width: 100%;
        justify-content: space-between;
        border-radius: 4px;
        cursor: pointer;
        transition: 200ms;

        @media screen and (max-width: #{$desktop - 1px}) {
          justify-content: space-between;
        }

        @media screen and (min-width: #{$desktop - 1px}) {
          gap: 32px;
        }

        &__tooltip {
          hr {
            width: 100%;
          }
          &--title {
            @extend %label-md;
            font-weight: 600;
            color: var(--color-Neutral-6);
          }
          &--row {
            display: flex;
            gap: 8px;
            span {
              @extend %label-md;
              color: var(--color-Neutral-4);
            }
          }
        }
      }
      &--first {
        @media screen and (max-width: #{$desktop - 1px}) {
          justify-content: space-between;
          flex: 1;
        }
      }
      &--first,
      &--second {
        &:hover {
          background-color: var(--color-surface-1);
        }
        &:hover &__tooltip {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
  .tabbed-container {
    .tab-item {
      border-top: 0;
      flex: 0 1;
    }
  }
  &__bet-entry {
    // margin: 0 auto 12px;
    // max-width: 490px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__my-bet {
    color: $color-grey4;
    font-size: 12px;
  }

  &__my-bet-picture {
    max-width: 20px;
    display: inline-block;
    margin-right: 0.5em;
    vertical-align: middle;
  }

  &__my-bet-option {
    display: inline-block;
    vertical-align: middle;
  }

  &__number-of-bets,
  &__volume {
    &--labels {
      padding: 5px 6px;
      border-radius: 4px;
      &:hover {
        background-color: var(--color-surface-1);
        opacity: 0.5;
      }
    }
  }

  &__number-of-bets,
  &__bet-end-date {
    &-text {
      display: flex;
      align-items: center;
      gap: 6px;
    }
    font-size: 14px;
    color: var(--color-Neutral-5);
    font-weight: 500;
    display: flex;
    align-items: flex-end;
    // margin-left: -5px;
    position: relative;
    cursor: pointer;
  }
  &__general-info-left__tooltip {
    left: 0;
    &-arrow {
      left: 62px;
    }
  }

  &__currency__tooltip {
    right: 0px;
    &-arrow {
      left: unset;
      right: 12px;
    }
  }

  &__volume,
  &__general-info-left--first,
  &__general-info-left--second,
  &__general-info-right,
  &__currency {
    &__tooltip {
      bottom: 40px;
      left: 10px;
      width: 260px;

      @media screen and (max-width: #{$desktop - 1px}) {
        bottom: 30px;
        left: 0;
        // width: 90%;
      }
      opacity: 0;
      pointer-events: none;
      transition: 100ms ease-out opacity;
      .activity-count__divider {
        display: none;
      }
      .tooltip__content {
        background: var(--color-Neutral-1);
        color: var(--color-Neutral-6);
        border: 1px solid var(--color-Neutral-3);
        padding: 16px;
        box-shadow: 0px 2px 8px 0 rgb(0 0 0 / 15%);
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        display: flex;
        gap: 11px;
        flex-direction: column;
        align-items: flex-start;
      }
      &-arrow {
        @media screen and (max-width: #{$desktop - 1px}) {
          left: 50%;
        }
        bottom: -5px;
        width: 10px;
        height: 10px;
        top: auto;
        background: var(--color-Neutral-1);
        border-bottom: 1px solid;
        border-right: 1px solid;
        border-color: var(--color-Neutral-1);
      }
    }
  }
  &__general-info-left--second {
    &__tooltip {
      @media screen and (max-width: #{$desktop - 1px}) {
        bottom: 50px;
        left: 5%;
        width: 70%;
      }
      &-arrow {
        @media screen and (max-width: #{$desktop - 1px}) {
          left: 10%;
        }
      }
    }
  }
  &__currency {
    &__tooltip {
      @media screen and (max-width: #{$desktop - 1px}) {
        bottom: 29px;
        left: unset !important;
        right: 0;
        width: 70%;
      }
      &-arrow {
        @media screen and (max-width: #{$desktop - 1px}) {
          right: 10%;
          left: unset !important;
        }
      }
    }
  }

  &__wager-count {
    display: inline-block;
    line-height: 1;
  }

  &__results-label,
  &__resolved-label,
  &__cancelled-label,
  &__start-date-label {
    font-size: 12px;
    color: #4c626d;
    margin: 1rem 0;
    display: flex;
    gap: 0.5rem;
    align-items: flex-end;
  }
  &__currency {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    font-weight: 700;
    padding: 5px 6px;
    @media screen and (max-width: #{$desktop - 1px}) {
      padding: 0;
    }
    border-radius: 4px;
    cursor: pointer;
    transition: 200ms;
    position: relative;
    &__tooltip {
      max-width: 330px;
      p {
        color: #6b6a6a;
        font-size: 14px;
      }
    }
    &:hover {
      background-color: var(--color-Neutral-3);
    }
    &:hover &__tooltip {
      opacity: 1;
      pointer-events: auto;
      width: 330px;
    }
  }
  &__volume {
    &-text {
      display: flex;
      align-items: center;
      gap: 6px;
      color: var(--color-Neutral-4);
    }
    font-size: 14px;
    font-weight: 500;
    display: flex;
    cursor: pointer;
    align-items: flex-end;
    font-family: 'Lato', Arial, sans-serif;
    justify-content: flex-end;
    margin-right: -5px;
    position: relative;
  }

  &__cancelled-label {
    color: $color-pink;
    text-transform: uppercase;
    font-weight: 700;
  }

  &__resolved-label {
    strong {
      text-transform: uppercase;
    }
  }

  &__person-icon {
    margin-right: 0.25em;
  }

  &__latest-bets {
    background-color: var(--color-Neutral-1);
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
    h4 {
      margin-bottom: 24px;
    }
    .button {
      margin-left: 20px;
      margin-right: 20px;
      max-width: calc(100% - 40px);
    }
  }

  &__comment {
    position: relative;
    width: 100%;
    max-width: 100%;
    background-color: var(--color-Neutral-1);
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
    h4 {
      margin-bottom: 24px;
    }
  }
  &__related-markets {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__slider {
      display: flex;
      position: relative;
    }
    &__head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }
  }

  &__bet-status {
    min-height: 13px;
    position: relative;
    overflow: visible;
    padding: 20px;
    background-color: var(--color-Neutral-1);
  }

  &__bet-status-picture {
    position: absolute;
    top: -13px;
    left: calc(50% - 13px);
    width: 26px;
    height: 26px;
    z-index: 1;
  }

  .question-date {
    margin-bottom: 0;
  }

  &__make-bet-success {
    background: linear-gradient(180deg, $color-grey0 0%, rgba(245, 245, 245, 0) 100%);
  }

  &--is-modal &__section {
    padding: 32px 24px 28px;
  }
  &--is-selected {
    // border-color: var(--color-primary-pure);
  }

  & &__make-bet {
    padding: 48px 24px 32px;
  }

  @media screen and (max-width: #{$desktop - 1px}) {
    &__bet-status {
      margin-top: 0;
    }
  }

  @media screen and (min-width: $tablet) {
    &__general-info-left__tooltip,
    &__general-info-right__tooltip {
      .tooltip__content {
        grid-template-rows: none;
        grid-template-columns: max-content 2px max-content;
        grid-gap: 8px;
      }

      .activity-count__divider {
        display: inline;
      }
    }

    &__modal-wrap {
      margin: 32px 0;
    }

    &--is-modal {
      padding: 0;
      padding-top: 2rem;
    }
  }

  @media screen and (min-width: $desktop) {
    margin-bottom: 20px;
  }
}
